import image from "../assets/images";

export default [
  {
    title: "Brightcove",
    id: "Brightcove mock",
    description:
      "Branding Brightcove Beacon mock-up is a generic mockup with all the platforms envolved",
    tags: ["beacon", "brightcove", "web", "apple tv", "mobile"],
    image: image.poc.brightcoveBeacon,
    url: "",
    links: [
      {
        title: "Web"
      },
      {
        title: "Mobile"
      },
      {
        title: "Apple TV"
      },
      {
        title: "SmartTv"
      },
      {
        title: "Roku"
      },
      {
        title: "Tablet"
      },
      {
        title: "Mock-up",
        url: "https://drive.google.com/drive/folders/1Pr1N5-ibrwJXkJHfPHIideICieumJZ2i?usp=sharing",
      },
    ],
},
{
  title: "AMC",
  id: "AMC",
  description:
    "The team created Beacon mockup for Web platform incorporating the live event page with the remind and countdown",
  tags: ["beacon", "AMC", "web"],
  closeWon: false,
  image: image.poc.amcbeacon,
  url: "https://brightcove.atlassian.net/browse/VALUEENG-644",
  links: [
    {
      title: "Tablet",
      url: "https://www.figma.com/proto/ZmPw2fdrwyYn3VAGsHKpnv/VE-644-AMC?page-id=502%3A4&node-id=506%3A2253&viewport=85%2C442%2C0.12&scaling=min-zoom"
    },
    {
      title: "Web",
      url: "https://www.figma.com/proto/ZmPw2fdrwyYn3VAGsHKpnv/VE-644-AMC?page-id=502%3A2&node-id=502%3A27&viewport=85%2C419%2C0.12&scaling=min-zoom"
    },
    {
      title: "Mobile",
      url: "https://www.figma.com/proto/ZmPw2fdrwyYn3VAGsHKpnv/VE-644-AMC?page-id=502%3A3&node-id=506%3A69&viewport=77%2C385%2C0.32&scaling=min-zoom"
    },
    {
      title: "Smart TV",
      url: "https://www.figma.com/proto/ZmPw2fdrwyYn3VAGsHKpnv/VE-644-AMC?page-id=502%3A5&node-id=507%3A2860&viewport=81%2C442%2C0.09&scaling=contain"
    },
    {
      title: "Roku",
      url: "https://www.figma.com/proto/ZmPw2fdrwyYn3VAGsHKpnv/VE-644-AMC?page-id=502%3A8&node-id=507%3A10046&viewport=708%2C475%2C0.09&scaling=contain"
    },
    ,
    {
      title: "Apple TV",
      url: "https://www.figma.com/proto/ZmPw2fdrwyYn3VAGsHKpnv/VE-644-AMC?page-id=502%3A6&node-id=507%3A344&viewport=78%2C443%2C0.06&scaling=contain"
    },
    {
      title: "Value Engineering Story",
      url: "https://brightcove.atlassian.net/browse/VALUEENG-644",
    },
    {
      title: "Figma Mock-up",
      url: "https://www.figma.com/file/ZmPw2fdrwyYn3VAGsHKpnv/VE-644-AMC?node-id=502%3A4&t=c2pV0a1fwRdtsnUX-0",
    },
  ],
},
    {
        title: "CGTN",
        id: "CGTN",
        description:
          "Upon request, the team created a set of Web, Mobile and Apple TV Live Demo for CGTN. This demo after some weeks has been replaced by TCS demo",
        tags: ["beacon", "CGTN", "web", "apple tv", "mobile"],
        image: image.poc.cgtn,
        url: "https://brightcove.atlassian.net/browse/VALUEENG-639",
        links: [
          {
            title: "Opportunity Link",
            url: "https://brightcove.my.salesforce.com/?ec=302&startURL=%2F0066f00001BrQPi%3FsrPos%3D0%26srKp%3D006"
          },
          {
            title: "Value Engineering Story",
            url: "https://brightcove.atlassian.net/browse/VALUEENG-639",
          }

        ],
    },
    {
        title: "BJP",
        id: "bjp",
        description:
          "The Value Engineering team delivered a set of Beacon mock-ups for BJP. These designs feature a set of assets that were specially created for this opportunity. Elevating the perceived experience, and leveraging the prospect’s branding and existing content throughout.",
        tags: ["beacon", "salesforce", "web", "apple tv", "mobile"],
        image: image.poc.bjp,
        url: "https://brightcove.atlassian.net/browse/VALUEENG-604",
        links: [
          {
            title: "Value Engineering Story",
            url: "https://brightcove.atlassian.net/browse/VALUEENG-604",
          },
          {
            title: "Tablet",
            url: "https://drive.google.com/drive/folders/1xG8OJ-U6odEuNzOdnevPy1gGf0h_c7Fw"
          },
          {
            title: "Web",
            url: "https://drive.google.com/drive/folders/169IjVu14SmQ-s69imWLFLk_Fl_6ny5eZ"
          },
          {
            title: "Mobile",
            url: "https://drive.google.com/drive/folders/1-MTfdsQ9tMLrV24Se7CQrOEs9PPSQHrh"
          },
          {
            title: "Smart TV",
            url: "https://drive.google.com/drive/folders/1zxVZHTTTxDWb5CAsp-bqSpNOAUSN05fz"
          },
          {
            title: "Apple TV",
            url: "https://drive.google.com/drive/folders/13ZJzOM0gpcpAtnPVWlCX8jpk_XEwB83O"
          },
        ],
    },
    {
        title: "AmazingFacts",
        id: "AmazingFacts",
        description:
          "Beacon mock-ups for AmazingFacts. Accompanying this request was a guidance document which detailed the text content in order to further customize the mock-ups. The result; a complete suite of Beacon mock-ups which utilize the prospect’s branding and existing content, enhanced by selectively using highlights of brand-related color schemes.",
        tags: ["beacon", "AmazingFacts", "web", "apple tv", "mobile"],
        image: image.poc.AmazingFacts,
        url: "https://brightcove.atlassian.net/browse/VALUEENG-605",
        links: [
          {
            title: "Value Engineering Story",
            url: "https://brightcove.atlassian.net/browse/VALUEENG-605",
          },
          {
            title: "Tablet",
            url: "https://drive.google.com/drive/folders/1-yXGmYSleoZTBfKjLUzEbng-fFVsxuyh"
          },
          {
            title: "Web",
            url: "https://drive.google.com/drive/folders/10eKCSS5cyw-NHr1u3GqMZugoqYDcUGWX"
          },
          {
            title: "Mobile",
            url: "https://drive.google.com/drive/folders/1d9yeb0_esEUTwXXeCeceEiMayiNr-viq"
          },
          {
            title: "Roku",
            url: "https://drive.google.com/drive/folders/1DpIU6Kgb3_jYD98Daz_DdNSFwq_fdF5b"
          },
          {
            title: "Apple TV",
            url: "https://drive.google.com/drive/folders/1aP0GQaLvQqzXTn6qrIyBAzoCNtMwKk-O"
          },
        ],
    }

];