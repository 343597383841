import React, { Component, Fragment } from "react";
import { withOktaAuth } from "@okta/okta-react";
import HomeLayout from "../../layouts/Home";
import SignIn from "../../../SignIn";
import Footer from "../../../Core/components/Footer";

class Home extends Component {
  componentDidMount() {
    const { authState, oktaAuth } = this.props;
    if (!authState) {
      console.log("auth");
      //oktaAuth.signInWithRedirect({ originalUri: "/" });
    }
  }

  render() {
    /* const { authState } = this.props;

    if (!authState) {
      return <SignIn message={"REDIRECT TO OKTA SIGN IN..."} />;
    }

    if (authState.isAuthenticated === false) {
      return <SignIn message={"REDIRECTING..."} />;
    } */
    return (
      <Fragment>
        <HomeLayout />
        <Footer />
      </Fragment>
    );
  }
}

export default withOktaAuth(Home);
