import image from '../assets/images';

export default [
    {
        title: "Intuit",
        id: "Intuit",
        description:
          "A complete Live Event Gallery solution was created for this opportunity, showcasing the advantages of simplified workflows, live streaming features, and Pigeonhole integration. CODE: intuit-demo",
        tags: ["gallery"],
        image: image.poc.intuit,
        url: "https://intuit-during.gallery.video/",
        links: [
          {
            title: "Value Engineering Story",
            url: "https://brightcove.atlassian.net/browse/VALUEENG-666",
          },
          {
            title: "PoC Link Pre",
            url: "https://intuit-pre.gallery.video/",
          },
          {
            title: "PoC Link During",
            url: "https://intuit-during.gallery.video/",
          },
          {
            title: "PoC Link During",
            url: "https://intuit-post.gallery.video/",
          },
        ],
      },
      {
        title: "Collibra",
        id: "collibra",
        description:
          "This opportunity was created to show the features of our Live In-page event gallery to Collibra company integrating its entire brand and styles",
        tags: ["gallery"],
        image: image.poc.collibra,
        url: "https://collibra.gallery.video/pre",
        links: [
          {
            title: "Value Engineering Story",
            url: "https://brightcove.atlassian.net/browse/VALUEENG-630",
          },
          {
            title: "PoC Link Pre",
            url: "https://collibra.gallery.video/pre",
          },
          {
            title: "PoC Link During",
            url: "https://collibra.gallery.video/during",
          },
          {
            title: "PoC Link During",
            url: "https://collibra.gallery.video/post/",
          }
        ],
      },

]