import image from "../assets/images";

export default [
  {
    title: "AI Main Page",
    description:
      "Explore the full range of AI-powered solutions from Brightcove, covering content creation, management, optimization, engagement, monetization, and quality assurance.",
    tags: ["ai", "video", "automation", "monetization"],
    image: image.poc.ai_suite,
    url: "https://vet.brightcove.com/ai-suite",
    links: [
      {
        title: "Brightcove AI Suite",
        url: "https://vet.brightcove.com/ai-suite",
      },
    ],
  },
  {
    title: "AI Content Multiplier",
    description:
      "AI generated vertical video shorts, highlights and chapters. This implementation analyzes your video, identifies key moments, and automatically creates concise, compelling snippets that maintain the essence of the original content.",
    tags: ["ai"],
    image: image.poc.ai_multiplier,
    url: "https://ai4.brightcovegallery.com/",
    links: [
      {
        title: "MARKETING DEMO",
        url: "https://interview.brightcovegallery.com/",
      },
      {
        title: "SPORTS TENIS DEMO",
        url: "https://auopen.brightcovegallery.com",
      },
      {
        title: "SOCCER DEMO",
        url: "https://spurs.gallery.video",
      },
    ],
  },
  {
    title: "AI Universal Translator",
    description: "High quality, AI-generated translations.",
    tags: ["ai"],
    image: image.poc.ai_translator,
    url: "https://vet.brightcove.com/ai-universal-translator/",
    links: [
      {
        title: "https://vet.brightcove.com/ai-universal-translator/",
        url: "https://vet.brightcove.com/ai-universal-translator/",
      },
    ],
  },
  {
    title: "AI Engagement Maximizer",
    description:
      "AI-based recommendations tailored to your audience, taking into account factors such as time of day, device type, viewing habits, and more. This powerful feature enhances content personalization, ensuring that users receive the most relevant and engaging suggestions. This demo showcasing our advanced AI-driven recommendation system was proudly presented at IBC 2024,",
    tags: ["ai"],
    image: image.poc.ai_engagement,
    url: "https://vet.brightcove.com/ibc-personalized/",
    links: [
      {
        title: "https://vet.brightcove.com/ibc-personalized/",
        url: "https://vet.brightcove.com/ibc-personalized/",
      },
    ],
  },
  {
    title: "AI Metadata Optimizer",
    description:
      "The AI Metadata Optimizer Pilot Program by Value Engineering. Contains the latest features.",
    tags: ["ai"],
    image: image.poc.ai_metadata,
    url: "https://vet.brightcove.com/ai-metadata/login",
    links: [
      {
        title: "https://vet.brightcove.com/ai-metadata/login",
        url: "https://vet.brightcove.com/ai-metadata/login",
      },
      {
        title: "Requires an account configured by Value Engineering.",
      },
    ],
  },
  {
    title: "AI Cost-to-Quality Optimizer",
    description:
      "A demo envinronment where you can showcase real-time statistics and compare regular streams vs AI Optimized streams. Choose from a collection of videos or bring your own. Please note that this feature is not currently available on Safari.",
    tags: ["ai"],
    image: image.poc.ai_quality_optimizer,
    url: "https://services.vet.brightcove.com/cae",
    links: [
      {
        title: "https://services.vet.brightcove.com/cae",
        url: "https://services.vet.brightcove.com/cae",
      },
      {
        title: "VPN Required",
      },
    ],
  },
  {
    title: "AI Credit Calculator",
    description:
      "An internal tool for estimating AI resource usage and credit consumption within Brightcove's AI services.",
    tags: ["ai", "calculator", "internal tool", "resource management"],
    image: image.poc.ai_credit_calculator,
    url: "https://services.vet.brightcove.com/ai-credit-calculator/",
    links: [
      {
        title: "AI Credit Calculator",
        url: "https://services.vet.brightcove.com/ai-credit-calculator/",
      },
    ],
  },
];
