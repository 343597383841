export default {
    es: {
        common: {
        },
        header: {
        },
        home: {
            title: 'Mira por qué el video es mejor con Brightcove.',
        },
    },
    en: {
        common: {
        },
        header: {
        },
        home: {
            title: 'See why video is better with Brightcove.',
        },
    },
};
