import React, { Component } from "react";
import Header from "../../../Core/components/Header";
import Card from "../../components/Card";
// import image from '../../../../assets/images';
import virtualEvents from "../../../../data/virtual-events";
import virtualEventsEnterprise from "../../../../data/virtual-events-ent";
import tools from "../../../../data/tools";
import functional from "../../../../data/functional";
import logo from "../../../../assets/images/icons/vet-logo.png";
import close from "../../../../assets/images/icons/close.png";
import menu from "../../../../assets/images/icons/menu.png";
// import other from '../../../../data/other';
import Hapyak from "../../../../data/interactivity";
import demos from "../../../../data/demos";
import corptv from "../../../../data/corp-tv";
import beacon from "../../../../data/beacon";
//import ai from "../../../../data/ai";

//Demos subdomain
import ai from "../../../../data/ai-demos";
import Drive from "../../../../data/drive-demos";
import monetization from "../../../../data/monetization-demos";

// Import CSS modules
import "./index.scss";

const changeArray = (category, visible) => {
  let aux;
  if (visible.includes(category))
    aux = visible.filter((data) => {
      return data !== category;
    });
  // aux = visible.filter((data) => {
  //   data != category;
  // });
  else aux = [...visible, category];
  return aux;
};

class HomeLayout extends Component {
  constructor(props) {
    super(props);
    this.eleVirtualEvents = React.createRef();
    this.eleVirtualEventsEnt = React.createRef();
    this.eleBeacon = React.createRef();
    this.eleSalesEngineering = React.createRef();
    this.eleFunctional = React.createRef();
    // this.eleOther = React.createRef()
    this.eleInteractivity = React.createRef();
    this.eleCorpTV = React.createRef();
    this.eleDemos = React.createRef();
    this.eleContact = React.createRef();
    this.state = {
      showSidebar: false,
      visibleCategories: [
        "bc-ai",
        "bc-monetization",
        "bc-poc",
        "bc-setools",
        "bc-drive",
        "bc-corptv",
        "bc-veb",
        "bc-vee",
      ],
    };
  }

  onSuccess = () => {};

  render() {
    return (
      <div className="row home margin-b50">
        <div className="col-md-10 col-md-offset-1">
          <nav className="header">
            <div className="sticky-header">
              <div className="flex">
                <img
                  src={menu}
                  className="menu"
                  onClick={() => {
                    this.setState({ ...this.state, showSidebar: true });
                  }}
                />
                <Header />
              </div>
            </div>
          </nav>

          {this.state.showSidebar ? (
            <div className="sidebar-container">
              <div className="sidebar">
                <img
                  src={close}
                  className="sidebar-close"
                  onClick={() => {
                    this.setState({ ...this.state, showSidebar: false });
                  }}
                />
                <img src={logo} className="vet-logo" />

                <div
                  className="sidebar-option bc-ai flex"
                  onClick={() => {
                    const aux = changeArray("bc-ai", [
                      ...this.state.visibleCategories,
                    ]);
                    this.setState({
                      ...this.state,
                      visibleCategories: [...aux],
                    });
                  }}
                >
                  <div>
                    <div
                      className={`checkbox ${
                        this.state.visibleCategories.includes("bc-ai")
                          ? "checkbox-checked"
                          : null
                      }`}
                    >
                      <div
                        className={
                          this.state.visibleCategories.includes("bc-ai")
                            ? "checked"
                            : null
                        }
                      ></div>
                    </div>
                  </div>
                  BRIGHTCOVE AI
                </div>

                <div
                  className="sidebar-option bc-drive flex"
                  onClick={() => {
                    const aux = changeArray("bc-drive", [
                      ...this.state.visibleCategories,
                    ]);
                    this.setState({
                      ...this.state,
                      visibleCategories: [...aux],
                    });
                  }}
                >
                  <div>
                    <div
                      className={`checkbox ${
                        this.state.visibleCategories.includes("bc-drive")
                          ? "checkbox-checked"
                          : null
                      }`}
                    >
                      <div
                        className={
                          this.state.visibleCategories.includes("bc-drive")
                            ? "checked"
                            : null
                        }
                      ></div>
                    </div>
                  </div>
                  BRIGHTCOVE ENGAGEMENT
                </div>

                <div
                  className="sidebar-option bc-monetization flex"
                  onClick={() => {
                    const aux = changeArray("bc-monetization", [
                      ...this.state.visibleCategories,
                    ]);
                    this.setState({
                      ...this.state,
                      visibleCategories: [...aux],
                    });
                  }}
                >
                  <div>
                    <div
                      className={`checkbox ${
                        this.state.visibleCategories.includes("bc-monetization")
                          ? "checkbox-checked"
                          : null
                      }`}
                    >
                      <div
                        className={
                          this.state.visibleCategories.includes(
                            "bc-monetization"
                          )
                            ? "checked"
                            : null
                        }
                      ></div>
                    </div>
                  </div>
                  MONETIZATION
                </div>

                <div
                  className="sidebar-option bc-poc flex"
                  onClick={() => {
                    const aux = changeArray("bc-poc", [
                      ...this.state.visibleCategories,
                    ]);
                    this.setState({
                      ...this.state,
                      visibleCategories: [...aux],
                    });
                  }}
                >
                  <div>
                    <div
                      className={`checkbox ${
                        this.state.visibleCategories.includes("bc-poc")
                          ? "checkbox-checked"
                          : null
                      }`}
                    >
                      <div
                        className={
                          this.state.visibleCategories.includes("bc-poc")
                            ? "checked"
                            : null
                        }
                      ></div>
                    </div>
                  </div>
                  PROOF OF CONCEPTS
                </div>

                <div
                  className="sidebar-option bc-setools flex"
                  onClick={() => {
                    const aux = changeArray("bc-setools", [
                      ...this.state.visibleCategories,
                    ]);
                    this.setState({
                      ...this.state,
                      visibleCategories: [...aux],
                    });
                  }}
                >
                  <div>
                    <div
                      className={`checkbox ${
                        this.state.visibleCategories.includes("bc-setools")
                          ? "checkbox-checked"
                          : null
                      }`}
                    >
                      <div
                        className={
                          this.state.visibleCategories.includes("bc-setools")
                            ? "checked"
                            : null
                        }
                      ></div>
                    </div>
                  </div>
                  SALES ENGINEERING TOOLS
                </div>

                <div
                  className="sidebar-option bc-corptv flex"
                  onClick={() => {
                    const aux = changeArray("bc-corptv", [
                      ...this.state.visibleCategories,
                    ]);
                    this.setState({
                      ...this.state,
                      visibleCategories: [...aux],
                    });
                  }}
                >
                  <div>
                    <div
                      className={`checkbox ${
                        this.state.visibleCategories.includes("bc-corptv")
                          ? "checkbox-checked"
                          : null
                      }`}
                    >
                      <div
                        className={
                          this.state.visibleCategories.includes("bc-corptv")
                            ? "checked"
                            : null
                        }
                      ></div>
                    </div>
                  </div>
                  CORPTV
                </div>

                <div
                  className="sidebar-option bc-veb flex"
                  onClick={() => {
                    const aux = changeArray("bc-veb", [
                      ...this.state.visibleCategories,
                    ]);
                    this.setState({
                      ...this.state,
                      visibleCategories: [...aux],
                    });
                  }}
                >
                  <div>
                    <div
                      className={`checkbox ${
                        this.state.visibleCategories.includes("bc-veb")
                          ? "checkbox-checked"
                          : null
                      }`}
                    >
                      <div
                        className={
                          this.state.visibleCategories.includes("bc-veb")
                            ? "checked"
                            : null
                        }
                      ></div>
                    </div>
                  </div>
                  VIRTUAL EVENTS FOR BUSINESS
                </div>

                <div
                  className="sidebar-option bc-vee flex"
                  onClick={() => {
                    const aux = changeArray("bc-vee", [
                      ...this.state.visibleCategories,
                    ]);
                    this.setState({
                      ...this.state,
                      visibleCategories: [...aux],
                    });
                  }}
                >
                  <div>
                    <div
                      className={`checkbox ${
                        this.state.visibleCategories.includes("bc-vee")
                          ? "checkbox-checked"
                          : null
                      }`}
                    >
                      <div
                        className={
                          this.state.visibleCategories.includes("bc-vee")
                            ? "checked"
                            : null
                        }
                      ></div>
                    </div>
                  </div>
                  VIRTUAL EVENTS FOR ENTERPRISE
                </div>
              </div>
            </div>
          ) : null}

          {this.state.visibleCategories.includes("bc-ai") ? (
            <div>
              <div className="h1-cont row" id="eleFunctional">
                <h1 className="bc-poc" ref={this.eleFunctional}>
                  BRIGHTCOVE AI SUITE
                </h1>
              </div>
              <Card data={ai} />
            </div>
          ) : null}

          {this.state.visibleCategories.includes("bc-monetization") ? (
            <div>
              <div className="h1-cont row" id="eleMonetization">
                <h1 className="bc-monetization" ref={this.eleMonetization}>
                  DRIVE ENGAGEMENT
                </h1>
              </div>
              <Card data={Drive} />
            </div>
          ) : null}

          {/* <div className="h1-cont row">
            <h1 className="bc-demos" ref={this.eleDemos}>
              DEMOS
            </h1>
          </div>
          <Card data={demos} /> */}

          {this.state.visibleCategories.includes("bc-poc") ? (
            <div>
              <div className="h1-cont row" id="eleFunctional">
                <h1 className="bc-poc" ref={this.eleFunctional}>
                  Proofs of concepts
                </h1>
              </div>
              <Card data={functional} />
            </div>
          ) : null}

          {this.state.visibleCategories.includes("bc-setools") ? (
            <div>
              <div className="h1-cont row" id="eleSalesEngineering">
                <h1 className="bc-setools" ref={this.eleSalesEngineering}>
                  SALES ENGINEERING TOOLS
                </h1>
              </div>
              <Card data={tools} />
            </div>
          ) : null}

          {this.state.visibleCategories.includes("bc-drive") ? (
            <div>
              <div className="h1-cont row" id="eleDrive">
                <h1 className="bc-drive" ref={this.eleDrive}>
                  DRIVE ENGAGEMENT
                </h1>
              </div>
              <Card data={Drive} />
            </div>
          ) : null}

          {this.state.visibleCategories.includes("bc-corptv") ? (
            <div>
              <div className="h1-cont row" id="eleCorpTV">
                <h1 className="bc-corptv" ref={this.eleCorpTV}>
                  CORP TV
                </h1>
              </div>
              <Card data={corptv} />
            </div>
          ) : null}

          {this.state.visibleCategories.includes("bc-veb") ? (
            <div>
              <div className="h1-cont row" id="eleVirtualEvents">
                <h1 className="bc-veb" ref={this.eleVirtualEvents}>
                  VIRTUAL EVENTS FOR BUSINESS
                </h1>
                <h5>
                  VE for Business is an easy-to-use virtual event solution,
                  ideal for mid-sized, self-service events for enterprises and
                  brands.
                </h5>
                <h5>
                  VE for Business makes creating and launching internal and
                  external (or both) events fast and intuitive
                </h5>
              </div>
              <Card data={virtualEvents} />
            </div>
          ) : null}

          {this.state.visibleCategories.includes("bc-vee") ? (
            <div>
              <div className="h1-cont row" id="eleVirtualEventsEnt">
                <h1 className="bc-vee" ref={this.eleVirtualEventsEnt}>
                  VIRTUAL EVENTS FOR ENTERPRISE
                </h1>
                <h5>
                  VE for Enterprise is a full-featured platform that lets global
                  enterprises and leading brands create and deliver immersive
                  live experiences to share your story with thousands or even
                  millions of live attendees.
                </h5>
                <h5>
                  {" "}
                  VE for Enterprise is perfect for concerts, global trade shows,
                  sporting events, award ceremonies, fundraisers, and more.
                </h5>
              </div>
              <Card data={virtualEventsEnterprise} />
            </div>
          ) : null}

          <div className="divider"></div>

          <div className="h1-cont row" id="eleContact">
            <h1 className="contact-us" ref={this.eleContact}>
              Contact us
            </h1>
          </div>
          <div className="contact-us">
            {/* <div className="contact-content">
              <h4>Doug Overn</h4>
              <div className="contact-title">VP, Sales Engineering</div>
              <a href="mailto:dovern@brightcove.com">dovern@brightcove.com</a>
            </div> */}
            {/*<div className="contact-content">
              <h4>Matt Glynn</h4>
              <div className="contact-title">
                Sr. Director, Sales Engineering
              </div>
              <a href="mailto:mglynn@brightcove.com">mglynn@brightcove.com</a>
            </div>*/}
            {/*<div className="contact-content">
              <h4>Alan García</h4>
              <div className="contact-title">Manager, Sales Engineering</div>
              <a href="mailto:agarcia@brightcove.com">agarcia@brightcove.com</a>
            </div>*/}
            {/*<div className="contact-content">
              <h4>José Martínez</h4>
              <div className="contact-title">Sales Software Engineer</div>
              <a href="mailto:jcuevas@brightcove.com">jcuevas@brightcove.com</a>
            </div>*/}
            {/*<div className="contact-content">
              <h4>Luis García</h4>
              <div className="contact-title">Sales Software Engineer</div>
              <a href="mailto:jcuevas@brightcove.com">
                lquezada@brightcove.com
              </a>
            </div>*/}
          </div>
          <div className="divider"></div>
        </div>
      </div>
    );
  }
}

export default HomeLayout;
