import image from "../assets/images";

export default [
  {
    title: "Live Interactivity BGS v1.0.2",
    description:
      "Live Interactivity offers our customers the ability to amplify engagement and conversions with their audience by using a live chat experience, branded product overlays and interactive call-to-actions, all displayed on the Brightcove Player and in-sync with their live streaming event",
    tags: ["interactivity", "live", "BRIGHTCOVE INTERACTIVITY"],
    image: image.poc.liveint,

    url: "https://vet.brightcove.com/interactive/entertainment/",
    links: [
      {
        title:
          "These pages are responsive so customers can view them on their phone and desktop devices. We have created three experiences to help cover the multiple segments (entertainment, Corporate, E-commerce).",
      },
      {
        title:
          "If you need something custom for a specific customer you can put a request into the Value Engineering team through JIRA. Please give us 2-3 days to restyle the template with the customer's branding and content.  ",
      },
      {
        title:
          "DO NOT use them for every customer you speak with. Please be strategic on when you request a template to be styled for a customer. ",
      },
      {
        title:
          "The general templates should be enough for early discussion with customers to gather interest.",
      },
      {
        title:
          "CTA will pop up 10 seconds after the top of every minute and will last for 20 seconds on the screen",
      },
      {
        title:
          'In the header of each demo page you will see a button "Toggle Chat Position". If you click this it will show customers what the experience looks like with chat on and beside the player. ',
      },

      {
        title: "Entertainment (Please request Value Eng to enable Stream)",
        url: "https://vet.brightcove.com/interactive/entertainment/",
      },
      {
        title: "Products (Please request Value Eng to enable Stream)",
        url: "https://vet.brightcove.com/interactive/products/",
      },
      {
        title: "Corporate (Please request Value Eng to enable Stream)",
        url: "https://vet.brightcove.com/interactive/corporate/",
      },
    ],
  },

  {
    title: "BC Interactivity 'EDGE Fitness' - Personalized",
    description: "",
    tags: ["interactivity", "live", "BRIGHTCOVE INTERACTIVITY"],
    image: image.poc.edge,

    url: "https://vet.brightcove.com/interactive/sports/",
    links: [
      {
        title:
          "Site modal prompting user to enter name and age to populate HapYak dynamic annotations is now up.",
      },
      {
        title: "Sports beverages / lifestyle landing page.",
      },
      {
        title: "1st video. Titled “Which supplement is right for your workout“",
      },
      {
        title: "Dynamic annotations.",
      },
      {
        title:
          "Add to cart Widget / product image and product description in widget. / link to checkout URL in cart widget.",
      },
      {
        title: "2nd video. Titled 'Join The EDGE Club'",
      },
      {
        title: "Dynamic annotations",
      },
      {
        title: "Link ID badge image overlay to mock login page",
      },
      {
        title: "Checkout page",
      },
      {
        title: "Login page",
      },
      {
        title: "Demo Site",
        url: "https://vet.brightcove.com/interactive/sports/",
      },
    ],
  },
  {
    title: "FORD Live interactivity & Brightcove interactivity",
    description: "",
    tags: ["interactivity", "live", "BRIGHTCOVE INTERACTIVITY"],
    image: image.poc.ford_int,

    url: "https://vet.brightcove.com/ve610-ford/",
    links: [
      {
        title:
          "Demonstration of how Ford can utilize Brightcove Interactivity to sell more vehicles.  It will give consumers a video-based experience that will engage, educate, excite, and encourage",
      },
      {
        title: "POC 1 Live interactivity",
      },
      {
        title:
          "CloudPlayout live channel. Looping “F-150 Lightning Reveal“ (~34 minutes duration).",
      },
      {
        title:
          "CTA Live annotations linked to VET service: shows a CTA every minute, 10 second delay, shown for 30 seconds.",
      },
      {
        title: "Sendbird chat plugin. / Hidden by default.",
      },
      {
        title: "POC 2 BC Interactivity",
      },
      {
        title: "Styled player",
      },
      {
        title: "BC Interactivity",
      },
      {
        title:
          "CTAs synced to the mentioned product. Image, description, link to store.",
      },
      {
        title:
          "CTAs at the end of the video with links to store, Bronco info, and contact form. 00:52, 01:22, 01:33, 02:00, 02:37, 02:56, 3:20, 3:32.",
      },
      {
        title: "Demo Site",
        url: "https://vet.brightcove.com/ve610-ford/",
      },
    ],
  },
  {
    title: "Sephora",
    description:
      "This opportunity incorporate Gallery Portal with Hapyak add to card implementation",
    tags: ["gallery", "hapyak", "BRIGHTCOVE INTERACTIVITY"],
    image: image.poc.sephora,
    closeWon: false,
    url: "https://sephora.gallery.video/",
    links: [
      {
        title: "Catalogue template",
      },
      {
        title:
          "Sentiment Analysis (Thumbs Up/Down voting) component added to 6 videos in hero carousel.",
      },
      {
        title:
          "Add To Cart (appears at 00:31). on first video named Dewy Morning Skincare Routine for Dry Skin | Sephora Routines + Rituals",
      },
      {
        title: "41 videos divided on 6 collections.",
      },
      {
        title: "Sephora branding.",
      },
      {
        title: "Custom header and  footer.",
      },
      {
        title: "PoC Link",
        url: "https://sephora.gallery.video/",
      },
      {
        title: "Value Engineering Story",
        url: "https://brightcove.atlassian.net/browse/VALUEENG-536",
      },
      {
        title: "Opportunity",
        url: "https://brightcove.lightning.force.com/lightning/r/Opportunity/0066f000018RiUsAAK/view",
      },
    ],
  },
  {
    title: "Tata Cliq",
    id: "tatacliq",
    description:
      "This opportunity has been built to get Tata Cliq attention to our Brightcove incredible products, features included:",
    tags: ["gallery", "hapyak", "BRIGHTCOVE INTERACTIVITY"],
    image: image.poc.tataCliq,
    url: "https://solutions.brightcove.com/vet/tata-cliq",
    closeWon: false,
    links: [
      {
        title: "Custom website with an embedded player",
      },
      {
        title: "Plugin Chapter markers with name of the chapter",
      },
      {
        title:
          "5 interactive product images on (01:07, 2:00, 3:26, 4:48, 6:42)",
      },
      {
        title: "Custom web site with language selection / English - Spanish",
      },
      {
        title: "Value Engineering Story",
        url: "https://brightcove.atlassian.net/browse/VALUEENG-455",
      },
      {
        title: "PoC Link",
        url: "https://solutions.brightcove.com/vet/tata-cliq",
      },
      {
        title: "Opportunity",
        url: "https://brightcove.my.salesforce.com/0066f000017DLjG?srPos=0&srKp=006",
      },
    ],
  },
  {
    title: "Lowe’s Gallery PoC",
    id: "lowes",
    description:
      "The Value Engineering team built a Gallery PoC for the Americas Q1 2021 Lowe’s Hardware pursuit in the form of a formal RFP.   The buildout imagined videos as ‘ideas’ to build with the hardware products they sell. It incorporates Calls-to-Action (CTAs) employing Hapyak to take the users to where they can purchase the products.",
    tags: ["gallery", "virtual-events", "BRIGHTCOVE INTERACTIVITY"],
    image: image.poc.lowes,
    url: "http://site-321221.bcvp0rtal.com",
    links: [
      {
        title: "Marquee Portal Template",
      },
      {
        title: "Sentiment Analysis (Thumbs Up/Down voting)",
      },
      {
        title: "Opportunity",
        url: "https://na171.salesforce.com/0066f000013dJv6",
      },
      {
        title: "Value Engineering Story",
        url: "https://brightcove.atlassian.net/browse/VALUEENG-291",
      },
      {
        title: "PoC Link",
        url: "http://site-7687.bcvp0rtal.com/",
      },
    ],
  },
];
