import React, { Component } from 'react';

class Card extends Component {
    
    render() {

        const { data } = this.props;

        return (
            <>
                { data.map((e, i) =>
                <div className="row projects" key={ i } id={e.id}>
                    <div className={ `${ i % 2 === 0 ? 'flex-switch' : 'no-flex'}` } key={ i }>
                        <div className={ `col-md-7` }>
                            <h3>{ e.title } <span className={e.closeWon ? `closeWon` : undefined}>{e.closeWon ? `Closed Won` : undefined}</span></h3>
                            <p>{ e.description }</p>
                            { e.links.length !== 0 && (
                                <ul>
                                    { e.links.map((x, j) => <li key={ j }><a href={ x.url }>{ x.title }</a></li>) }
                                </ul>
                            ) }
                        </div>
                        <div className={ `col-md-5` }>
                            <div className="card-perspective">
                                <div className="card" style={ { backgroundImage: `url(${ e.image })` } }>
                                    <div className="card-left">
                                        <div className="card-image card-tilted-image tilt-left" ></div>
                                    </div>
                                    <div className="card-image card-body" ></div>
                                    <div className="card-right">
                                        <div className="card-image card-tilted-image tilt-right"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                ) }
            </>
        );
    }
}

export default Card;
