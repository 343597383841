export default {
  lantern: require("./lantern.png"),
  nike: require("./nike.png"),
  pandora: require("./pandora.png"),
  peloton: require("./peloton.png"),
  schlumberger: require("./schlumberger.png"),
  spotify: require("./spotify.png"),
  comerica: require("./comerica.png"),
  lowes: require("./lowes.png"),
  visaUniversity: require("./visa-university.png"),
  netflix: require("./netflix.png"),
  setools: require("./setools.png"),
  vebuilder: require("./ve-builder.png"),
  cvent: require("./cvent.png"),
  amc: require("./amc.png"),
  amcbeacon: require("./amc_beacon.png"),
  pigeonhole: require("./pigeonhole.png"),
  playtv: require("./playtv.png"),
  epg: require("./epg.png"),
  takeda: require("./takeda.png"),
  konicaMinolta: require("./konica-minolta.png"),
  ford: require("./ford.png"),
  otis: require("./otis.png"),
  coupang: require("./coupang.png"),
  calc: require("./calc.png"),
  stryker: require("./stryker-ve457.png"),
  salesforceplusbeacon: require("./salesforce-plus-beacon.png"),
  threeTelecoms: require("./three-telecoms.png"),
  forever21: require("./forever21.png"),
  beautycounter: require("./beautycounter.png"),
  pacapts: require("./pacapts.png"),
  fiserv: require("./fiserv.png"),
  keplerCheuvreux: require("./kepler-cheuvreux.png"),
  cloudera: require("./cloudera.png"),
  capitalOne: require("./capital-one.jpg"),
  spotifyVEE: require("./spotify-vee.png"),
  diageo: require("./diageo.jpg"),
  tvnPanama: require("./tvn-panama.png"),
  britishCouncil: require("./british-council.png"),
  ottawaHospital: require("./ottawa-hospital.png"),
  floorAndDecor: require("./floor-and-decor.png"),
  splunk: require("./splunk.png"),
  jonhsonTesco: require("./johnson-tesco.png"),
  ariat: require("./ariat.png"),
  walgreens: require("./walgreens.png"),
  sephora: require("./sephora.jpg"),
  tataCliq: require("./tata-cliq.png"),
  brightcoveBeacon: require("./bcovBeacon.png"),
  CharlesSchwab: require("./CharlesSchwab.png"),
  forceSports: require("./force.png"),
  megger: require("./megger.png"),
  liveint: require("./interactivity.png"),
  edge: require("./edge.png"),
  ford_int: require("./ford_int.png"),
  caeWizard: require("./cae-wizard.png"),
  enterpriseCalc: require("./enterprise-calc.png"),
  parler: require("./parler.png"),
  adt: require("./adt.png"),
  verticalVideo: require("./vertical-video.png"),
  videoVoiceMail: require("./video-voice-mail.png"),
  tcs: require("./tcs.png"),
  fordcorptv: require("./fordcorptv.png"),
  cgtn: require("./cgtn.png"),
  bjp: require("./bjp.png"),
  AmazingFacts: require("./AmazingFacts.png"),
  cisco: require("./cisco.png"),
  intuit: require("./intuit.png"),
  collibra: require("./collibra.png"),
  oracle: require("./oracle.png"),
  ai_suite: require("./bc_ai_suite.png"),
  ai_multiplier: require("./ai_content_multiplier.png"),
  ai_translator: require("./ai_translator.png"),
  ai_metadata: require("./ai_metadata.png"),
  ai_engagement: require("./ai_engagement.png"),
  ai_quality_optimizer: require("./ai_quality_optimizer.png"),
  ai_credit_calculator: require("./ai_credit_calculator.png"),
  qrsoverlay: require("./qrs-overlay.png"),
  livechat: require("./live-chat.png"),
  vodinteractivty: require("./vodinteractivty.png"),
  cloudplayoutepg: require("./cloudplayoutepg.png"),
};
