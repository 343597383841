import React, { Component } from 'react';
import './index.scss';

class Header extends Component {
    render() {
        return (
            <div className="footer">
                <div className="copy">© {(new Date().getFullYear())} Brightcove Inc. All rights reserved.</div>
            </div>
        );
    }
}

export default Header;
