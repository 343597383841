import image from "../assets/images";

export default [

  {
    title: "Ford",
    id: "ford",
    description:
      "Opportunity to keep our customer engaged with all diferent features Brightcove has",
    tags: ["gallery"],
    image: image.poc.ford,
    url: "https://ford-motor.brightcovegallery.com/during",
    links: [
      {
        title: "Value Engineering Story",
        url: "https://brightcove.atlassian.net/browse/VALUEENG-443",
      },
      {
        title: "PoC Link",
        url: "https://ford-motor.brightcovegallery.com/during",
      },
    ],
  },

  {
    title: "Stryker",
    id: "stryker",
    description:
      "This gallery was created with Stryker's National Sales Meeting in mind. Featuring Stryker branding throughout, as well as a branded Pigeonhole integration during the live event. The Stryker logo was added to the player control bar to further demonstrate the value of a truly customizable product, compared to their current solution.",
    tags: ["gallery"],
    image: image.poc.stryker,
    url: "https://stryker-live.brightcovegallery.com/",
    links: [
      {
        title: "Value Engineering Story",
        url: "https://brightcove.atlassian.net/browse/VALUEENG-457",
      },
      {
        title: "PoC Link",
        url: "https://stryker-live.brightcovegallery.com/",
      },
    ],
  },
  {
    title: "Three Telecoms",
    id: "threetelecoms",
    description:
      "This POC is important to capture Three Telecoms as a customer. We wanted to show the power of our Gallery product with Catalogue template",
    tags: ["gallery"],
    image: image.poc.threeTelecoms,
    url: "https://three.brightcovegallery.com/",
    links: [
      {
        title: "Value Engineering Story",
        url: "https://brightcove.atlassian.net/browse/VALUEENG-461",
      },
      {
        title: "PoC Link",
        url: "https://three.brightcovegallery.com/",
      },
    ],
  },
  {
    title: "Forever21",
    id: "forever21",
    description:
      "This POC is important to capture Forever21 attention with Live Event Portal Gallery template",
    tags: ["gallery"],
    image: image.poc.forever21,
    url: "https://forever21poc.gallery.video/pre",
    links: [
      {
        title: "Value Engineering Story",
        url: "https://brightcove.atlassian.net/browse/VALUEENG-469",
      },
      {
        title: "PoC Link",
        url: "https://forever21poc.gallery.video/pre",
      },
    ],
  },
  {
    title: "Kepler Cheuvreux",
    id: "keplercheuvreux",
    description:
      "Demo required by a potential client Kepler Cheuvreux using a Catalogue template with their branding to show our powerful products",
    tags: ["gallery"],
    image: image.poc.keplerCheuvreux,
    url: "https://keplercheuvreux.brightcovegallery.com",
    links: [
      {
        title: "Value Engineering Story",
        url: "https://brightcove.atlassian.net/browse/VALUEENG-471",
      },
      {
        title: "PoC Link",
        url: "https://keplercheuvreux.brightcovegallery.com",
      },
    ],
  },
  {
    title: "Capital One",
    id: "capitalone",
    description:
      "This request included two POCs for CapitalOne. We delivered a fully customized Gallery catalogue with social and search features, as well as a demo site for an In-Page experience, recreating the prospect’s blog page using an IPX, which included the corresponding branding, as well as interactive cards and links.",
    tags: ["gallery"],
    image: image.poc.capitalOne,
    url: "https://capitalone.brightcovegallery.com/",
    links: [
      {
        title: "Value Engineering Story",
        url: "https://brightcove.atlassian.net/browse/VALUEENG-476",
      },
      {
        title: "PoC Link",
        url: "https://capitalone.brightcovegallery.com/",
      },
      {
        title: "PoC Link In-Page",
        url: "https://vet.brightcove.com/ve476-capitalone/poc2",
      },
    ],
  },
  {
    title: "Diageo",
    id: "diageo",
    description:
      "A complete Live Event experience for Diageo. A very interesting build with a combination of unusual, yet elegant colors that match the prospect’s brand. Further customized by applying a header and footer, creating a video montage that matches the theme of the event; the unveiling of a new set of product lines. During the live event, we’ve used the Twitter integration available directly from Gallery, as well as took special attention to other Gallery aspects, such as the design of video detail pages.",
    tags: ["gallery"],
    image: image.poc.diageo,
    url: "https://diageo-pre.gallery.video/",
    links: [
      {
        title: "Value Engineering Story",
        url: "https://brightcove.atlassian.net/browse/VALUEENG-483",
      },
      {
        title: "PoC Link",
        url: "https://diageo-pre.gallery.video/",
      },
    ],
  },
  {
    title: "British Council",
    id: "britishcouncil",
    description:
      "POC Created to help a contract possibility with British Council, showing a Catalogue, Live Portal and IPX Grid.",
    tags: ["gallery"],
    image: image.poc.britishCouncil,
    url: "https://british-council.gallery.video/during",
    links: [
      {
        title: "Value Engineering Story",
        url: "https://brightcove.atlassian.net/browse/VALUEENG-491",
      },
      {
        title: "PoC Link Live",
        url: "https://british-council.gallery.video/during",
      },
      {
        title: "PoC Link Catalogue",
        url: "https://british-council.brightcovegallery.com",
      },
      {
        title: "PoC Link IPX Grid",
        url: "https://vet.brightcove.com/ve491-britishcouncil/poc3/",
      },
    ],
  },
  {
    title: "The Ottawa Hospital",
    id: "ottawahospital",
    description:
      "They are interested in running live events through BC as well as using us to replace their youtube channel with Gallery. They're also interested in using gallery behind a SSO gate for sensitive / subscription content",
    tags: ["gallery"],
    image: image.poc.ottawaHospital,
    url: "https://ottawahospital.brightcovegallery.com/pre",
    links: [
      {
        title: "Value Engineering Story",
        url: "https://brightcove.atlassian.net/browse/VALUEENG-492",
      },
      {
        title: "PoC Link Live",
        url: "https://ottawahospital.brightcovegallery.com/pre",
      },
      {
        title: "PoC Link Catalogue",
        url: "https://ottawahospital.gallery.video/",
      },
    ],
  },
  {
    title: "Floor And Decor",
    id: "flooranddecor",
    description:
      "Upon request, we assisted on a Gallery build. Adding a custom header and footer to further customize the Gallery experience, the result was a truly customized portal that completely matches the prospect’s brand and demonstrates both the flexibility and capabilities of the platform.",
    tags: ["gallery"],
    image: image.poc.floorAndDecor,
    url: "https://flooranddecor.gallery.video/portal1",
    links: [
      {
        title: "Value Engineering Story",
        url: "https://brightcove.atlassian.net/browse/VALUEENG-493",
      },
      {
        title: "PoC Link",
        url: "https://flooranddecor.gallery.video/portal1",
      },
    ],
  },
  {
    title: "Splunk",
    id: "splunk",
    description:
      "Gallery solution was created to show the different Galleries Brightcove has, Giving the best of BC.",
    tags: ["gallery"],
    image: image.poc.splunk,
    url: "https://splunk.gallery.video",
    links: [
      {
        title: "Value Engineering Story",
        url: "https://brightcove.atlassian.net/browse/VALUEENG-487",
      },
      {
        title: "PoC Link",
        url: "https://splunk.gallery.video",
      },
    ],
  },
  {
    title: "Tesco",
    id: "jonhsonTesco",
    description:
      "Johnson & Johnson Consumer Health do webinars to their audience of either medical students or partners such as Tesco, Asda, and Boots (retailers). They are running webinars with another platform, and they really want to focus on on demand as that is where they get more views and they don't have a good way of presenting it. We presented our Live Gallery Portal.",
    tags: ["gallery"],
    image: image.poc.jonhsonTesco,
    url: "https://tesco.gallery.video/pre",
    links: [
      {
        title: "Value Engineering Story",
        url: "https://brightcove.atlassian.net/browse/VALUEENG-486",
      },
      {
        title: "PoC Link",
        url: "https://tesco.gallery.video/pre",
      },
    ],
  },
  {
    title: "Ariat",
    id: "ariat",
    description:
      "We presented a POC Gallery Marquee site based on a full gallery example, simulating the way they can show their experiences.",
    tags: ["gallery"],
    image: image.poc.ariat,
    url: "https://ariat.gallery.video/",
    links: [
      {
        title: "Value Engineering Story",
        url: "https://brightcove.atlassian.net/browse/VALUEENG-482",
      },
      {
        title: "PoC Link",
        url: "https://ariat.gallery.video/",
      },
    ],
  },
];
