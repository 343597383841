import React from 'react';
import Routes from './routes';
import "./global.scss";

class App extends React.Component {
    render() {
        return <Routes />;
    }
}

export default App;
