import image from "../assets/images";

export default [
  {
    title: "LIVE Interactivity - QRs Overlays",
    id: "LIVE Interactivity - QRs Overlays",
    description: "",
    tags: ["drive", "engagement", "web"],
    closeWon: false,
    image: image.poc.qrsoverlay,
    url: "",
    links: [
      {
        title: "DEMO",
        url: "https://vet.brightcove.com/ibc-cloud-playout-demo/",
      },
    ],
  },
  {
    title: "LIVE Interactivity - CTAs, Chat, reactions",
    id: "LIVE Interactivity - CTAs, Chat, reactions",
    description: "",
    tags: ["drive", "engagement", "web"],
    closeWon: false,
    image: image.poc.livechat,
    url: "",
    links: [
      {
        title: "DEMO",
        url: "https://solutions.brightcove.com/agarrido/demo-live-ecommerce-formulae-bcovnews.html",
      },
    ],
  },
  {
    title: "VOD Interactivity",
    id: "VOD Interactivity",
    description:
      "Demo presentation on Brightcove Full Stream with features like a collapsible sidebar, branding with Brightcove logo overlay, dark theme, and chapters covering Image Overlay, Add to Cart, Transparent Overlay, Text Overlay, Poll, iFrame, Quiz, User Sentiment, Spin to Win (Custom), and Stock Ticker (Custom) at specified timestamps.",
    tags: ["drive", "engagement", "web"],
    closeWon: false,
    image: image.poc.vodinteractivty,
    url: "",
    links: [
      {
        title: "DEMO",
        url: "https://vet.brightcove.com/ve795-Interactivity/",
      },
    ],
  },
];
