import image from "../assets/images";

export default [
  {
    title: "AI Metadata Optimizer",
    id: "AI Metadata Optimizer",
    description: "",
    tags: ["monetization", "engagement", "web"],
    closeWon: false,
    image: image.poc.amcbeacon,
    url: "",
    links: [
      {
        title: "DEMO",
        url: "https://studio.brightcove.com/products/videocloud/media/videos",
      },
    ],
  },
  {
    title: "Cloud Playout with EPG",
    id: "Cloud Playout with EPG",
    description:
      "Cloud Playout with EPG demo showcasing the development of an Electronic Program Guide (EPG), featuring dynamic content scheduling and integration with cloud-based playout systems, allowing users to interact with real-time program information.",
    tags: ["monetization", "engagement", "web"],
    closeWon: false,
    image: image.poc.cloudplayoutepg,
    url: "",
    links: [
      {
        title: "DEMO",
        url: "https://vet.brightcove.com/ibc-epg-demo/",
      },
    ],
  },
  {
    title: "Subscribers Insights",
    id: "Subscribers Insights",
    description: "",
    tags: ["monetization", "engagement", "web"],
    closeWon: false,
    image: image.poc.amcbeacon,
    url: "",
    links: [
      {
        title: "DEMO",
        url: "https://insights.wicketlabs.com/dashboard/summary",
      },
    ],
  },
  {
    title: "Live Monetization",
    id: "Live Monetization",
    description: "",
    tags: ["monetization", "engagement", "web"],
    closeWon: false,
    image: image.poc.amcbeacon,
    url: "",
    links: [
      {
        title: "DEMO",
        url: "https://players.brightcove.net/6271190146001/default_default/index.html?videoId=6361143411112",
      },
    ],
  },
  {
    title: "Ad Insights Video Cloud",
    id: "Ad Insights Video Cloud",
    description: "",
    tags: ["monetization", "engagement", "web"],
    closeWon: false,
    image: image.poc.amcbeacon,
    url: "",
    links: [
      {
        title: "DEMO",
        url: "https://studio.brightcove.com/products/videocloud/analytics/ad_insights",
      },
    ],
  },
  {
    title: "Outstream Player - Auto play/pause",
    id: "Outstream Player - Auto play/pause",
    description: "",
    tags: ["monetization", "engagement", "web"],
    closeWon: false,
    image: image.poc.amcbeacon,
    url: "",
    links: [
      {
        title: "DEMO",
        url: "https://solutions.brightcove.com/slevitas/pm/outstream/outstream-demo.html",
      },
    ],
  },
  {
    title: "Outstream Player - Companion Ad",
    id: "Outstream Player - Companion Ad",
    description: "",
    tags: ["monetization", "engagement", "web"],
    closeWon: false,
    image: image.poc.amcbeacon,
    url: "",
    links: [
      {
        title: "DEMO",
        url: "https://solutions.brightcove.com/slevitas/pm/outstream/outstream-demo.html?companion=true",
      },
    ],
  },
  {
    title: "Outstream Player - Pinning",
    id: "Outstream Player - Pinning",
    description: "",
    tags: ["monetization", "engagement", "web"],
    closeWon: false,
    image: image.poc.amcbeacon,
    url: "",
    links: [
      {
        title: "DEMO",
        url: "https://solutions.brightcove.com/slevitas/pm/outstream/outstream-demo.html?pip=true",
      },
    ],
  },
];
