import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

class Header extends Component {
  render() {
    return (
      <div className="col-sm-12">
        <div className="header_container">
          <Link to={`https://www.brightcove.com`}>
            <div className="logo" />
          </Link>
        </div>
        <div className="header_container project-title">BRIGHTCOVE DEMOS</div>
      </div>
    );
  }
}

export default withRouter(Header);
