import image from "../assets/images";

export default [
  {
    title: "Vertical Video",
    id: "vertical-video",
    description:
      "This Poc have the goal to demonstrate how Brightcove player can handle vertical videos, using preloaded videos and a swipe transition ",
    tags: ["vertical-video", "player"],
    image: image.poc.verticalVideo,
    url: "http://solutions.brightcove.com/vet/pigeonhole-plugin/",
    links: [
      {
        title: "The experience is for mobile web (no SDK's)",
      },
      {
        title: "The videos are 9x16",
      },
      {
        title: "Videos pre load quickly",
      },
      {
        title: "Vertical Video Demo (with a simulated ad)",
        url: "https://vet.brightcove.com/ve626-vertical-video/",
      },
      {
        title: "Value Engineering Story",
        url: "https://brightcove.atlassian.net/browse/VALUEENG-626",
      },
    ],
  },
  {
    title: "Coupang",
    id: "coupang",
    description:
      "This PoC have the goal to demonstrate Video Cloud’s surround sound (5.1) and HEVC capabilities. Demo Features:",
    tags: ["coupang"],
    image: image.poc.coupang,
    url: "https://vet.brightcove.com/ve504-coupang/",
    links: [
      {
        title: "DRM plugin enabled.",
      },
      {
        title: "Chromecast receiver BC plugin enabled.",
      },
      {
        title: "Quality selection controls enabled.",
      },
      {
        title: "Value Engineering Story",
        url: "https://brightcove.atlassian.net/browse/VALUEENG-518",
      },
      {
        title: "PoC Link",
        url: "https://vet.brightcove.com/ve504-coupang/",
      },
    ],
  },
  {
    title: "Parler Live",
    id: "parler",
    description:
      "This opportunity has been built to help our Parler customer in its decision to implement a live stream",
    tags: ["gallery"],
    image: image.poc.parler,
    url: "https://vet.brightcove.com/ve643-parler/",
    links: [
      {
        title: "Value Engineering Story",
        url: "https://brightcove.atlassian.net/browse/VALUEENG-643",
      },
      {
        title: "PoC Link",
        url: "https://vet.brightcove.com/ve643-parler/",
      },
      {
        title: "Opportunity Link",
        url: "https://brightcove.lightning.force.com/lightning/r/Opportunity/0066f00001CQLCjAAP/view",
      },
    ],
  },

  {
    title: "EPG",
    id: "epg-plugin",
    description:
      "The team was tasked with creating an EPG (Electronic Program Guide) plugin, which enables the user to get information sourced from Video Cloud, regarding current and future programs, as well as browse between live channels.",
    tags: ["cloudplayout", "live", "epg", "player", "plugin"],
    image: image.poc.epg,
    url: "http://solutions.brightcove.com/vet/pigeonhole-plugin/",
    links: [
      {
        title: "EPG Demo",
        url: "https://vet.brightcove.com/plugins/epgCloudPlayout/epgv2.html",
      },
      {
        title: "Value Engineering Story",
        url: "https://brightcove.atlassian.net/browse/VALUEENG-422",
      },
    ],
  },
  {
    title: "AMC",
    id: "amc",
    description:
      "In Q1 2021, AMC was in the process of re-architecting their OTT services and we have been working with them to architect the new solution. We provided a working example showing AMC how to implement our APIs and Players based on their new architecture.",
    tags: ["gallery", "virtual-events"],
    image: image.poc.amc,
    url: "http://site-321221.bcvp0rtal.com",
    links: [
      {
        title: "Value Engineering Story",
        url: "https://brightcove.atlassian.net/browse/VALUEENG-290",
      },
      {
        title: "PoC Link",
        url: "https://vet.brightcove.com/amc/",
      },
    ],
  },
  {
    title: "Custom Built Ad Server",
    id: "adserver",
    description:
      "Brightcove built an OTT experience for the 2020 Play conference utilizing our Beacon platform. As part of this experience, the applications leveraged Brightcove’s SSAI to deliver ads for both Live and VoD. The PlayTV planning team asked Value Engineering to build a custom ad server that could respond and trigger the apps to play the desired ads or video bumpers at the appropriate intervals.",
    tags: ["react", "gallery", "virtual-events"],
    image: image.poc.playtv,
    url: "https://vet.brightcove.com/pads/?dur=15&pre=1",
    links: [
      {
        title: "Site",
        url: "https://vet.brightcove.com/pads/?dur=15&pre=1",
      },
      {
        title: "Value Engineering Story",
        url: "https://jira.brightcove.com/browse/VALUEENG-175",
      },
      {
        title: "Docs",
        url: "https://docs.google.com/document/d/1tr6cDSGsQ0kHlgs2sjiSEwgjNBEuKHFmmOCyWyEp2AA/edit#heading=h.gjdgxs",
      },
      {
        title: "Code",
        url: "https://bithub.brightcove.com/ValueEngineering/play-ad-server",
      },
    ],
  },
];
