import image from '../assets/images';

export default [
    {
        title: "TCS",
        id: "TCS",
        description:
          "CorpTV Live Demo for TCS branding and videos",
        tags: ["beacon", "TCS", "plus", "web", "apple tv", "mobile"],
        image: image.poc.tcs,
        url: "https://brightcove.atlassian.net/browse/VALUEENG-649",
        links: [
          {
            title: "CMSSE44",
            url: "https://beacon-web.ott.us-east-1.qa.deploys.brightcove.com/apiselect"
          },
          {
            title: "Value Engineering Story",
            url: "hhttps://brightcove.atlassian.net/browse/VALUEENG-649",
          }
        ],
    },
    {
      title: "Ford",
      id: "ford",
      description:
        "CorpTV mockups using Ford branding and videos with screenshots for Web, iOS, and Apple TV.",
      tags: ["beacon", "Ford", "plus", "web", "apple tv", "mobile"],
      image: image.poc.fordcorptv,
      url: "https://brightcove.atlassian.net/browse/VALUEENG-609",
      links: [
        {
          title: "Web",
          url: "https://drive.google.com/drive/u/0/folders/1tvwTZu-ZhVRSixQebDvhGnkrleXXmYXv"
        },
        {
          title: "Mobile",
          url: "https://drive.google.com/drive/u/0/folders/1m35tJnt8t5fX_5fDX3aODbUCGMmoNsi6"
        },
        {
          title: "Smart TV",
          url: "https://drive.google.com/drive/u/0/folders/1rEiKpr9Ryfeq3hduOWvA7U05IRoLVKlG"
        },
        {
          title: "Value Engineering Story",
          url: "https://brightcove.atlassian.net/browse/VALUEENG-609",
        },
        {
          title: "Figma Mock-up",
          url: "https://www.figma.com/file/oe2acV6PisE5oOhmHIgru7/609-Ford?node-id=502%3A2&t=48vGfmCfE1nEq7MJ-0",
        },
      ],
  },
  {
    title: "Cisco",
    id: "Cisco",
    description:
      "CorpTV mockups using Cisco branding and videos with screenshots for Web, iOS, and Apple TV.",
    tags: ["beacon", "Cisco", "plus", "web", "apple tv", "mobile"],
    image: image.poc.cisco,
    url: "https://brightcove.atlassian.net/browse/VALUEENG-573",
    links: [
      {
        title: "Value Engineering Story",
        url: "https://brightcove.atlassian.net/browse/VALUEENG-573",
      },
      {
        title: "Web",
        url: "https://drive.google.com/drive/folders/17u7kWFWJjIYIQ0jo8YIvcBDf1J2uwU1H"
      },
      {
        title: "Mobile",
        url: "https://drive.google.com/drive/folders/14saJ2N6NZPUWHvFbG_ALONe4a5ADBzl7"
      },
      {
        title: "Apple TV",
        url: "https://drive.google.com/drive/folders/14n8gcfYghysxofurq-ZmIXaUdJAi80sh"
      }

    ],
}
]