import image from '../assets/images';

export default [

  {
    title: "Lantern Excursions",
    id: "lanternexcursions",
    description:
      "This Brightcove Virtual Event Experience is using a fictional business scenario ‘Lantern Excursions’. The Lantern Excursions VEE features include:",
    tags: ["gallery", "virtual-events"],
    image: image.poc.lantern,
    url: "https://excursion-lastversion.brightcovegallery.com/",
    links: [
      {
          title: "Login & Registration validation via CVENT / CVENT sign-up"
      },
      {
        title: "Simulive plugin ,updated via API ready for demo it"
      },
      {
          title: "Pigeonhole Interactivity. Q/A , Surveys, Breaks configured"
      },
      {
        title: "7 Agenda sessions"
      },
      {
        title: "2 Speakers on the speakers section"
      },
      {
        title: "Demo Link",
        url: "https://excursion-lastversion.brightcovegallery.com/",
      },
    ],
  },
  {
    title: 'Oracle',
    id: "Oracle-vee",
    description: 'This virtual events portal built for the AMERICAS team, with features like: ',
    tags: ['react', 'gallery', 'virtual-events'],
    image: image.poc.oracle,
    closeWon: false,
    url: 'https://megger.gallery.video/',
    links: [
        {
            title: "PigeonHole integration"
        },
        {
            title: "Oracle Brading"
        },
        {
            title: "4 Agenda sessions"
          },
          {
            title: "3 Speakers on the speakers section"
          },
        {
            title: 'Opportunity',
            url: 'https://brightcove.lightning.force.com/lightning/r/Opportunity/0066f00001CTxc1AAD/view',
        },
        {
            title: 'Value Engineering Story',
            url: 'https://brightcove.atlassian.net/browse/VALUEENG-670',
        },
        {
            title: 'Demo Link CODE: oracle',
            url: 'https://oracle.gallery.video/',
        },
    ]
},

]