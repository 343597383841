import image from "../assets/images";

export default [
  {
    title: "Video Voice Mail",
    id: "video-voice-mail",
    description:
      "A handy tool to help you during the sales process. With this tool you can create short Picture-in-Picture videos using FFMPEG to send to prospects, this project has the next components",
    tags: ["VideoVoiceMail"],
    image: image.poc.videoVoiceMail,
    url: "https://services.vet.brightcove.com/vvm",
    links: [
      {
        title:
          "Dashboard where you can edit the content of video voicemails, enable/disable or delete a video voicemail",
      },
      {
        title: "Landing pages, where users can view a video voicemail details",
      },
      {
        title: "Link to Video Voice Mail",
        url: "https://services.vet.brightcove.com/vvm",
      },
      {
        title: "Example",
        url: "https://vvm.brightcove.com/1DVXqvkTa6Cb7PeSTFFv",
      },
    ],
  },
  {
    title: "Calculator",
    id: "calcualtor",
    description:
      "A JavaScript-based entitlements calculator for helping the sales team calculate entitlements for new deals and other opportunities",
    tags: ["calculator"],
    image: image.poc.calc,
    url: "https://solutions.brightcove.com/vet/bc_entitlements_calculator/vod_calc.html",
    links: [
      {
        title: "Link to Calculator",
        url: "https://solutions.brightcove.com/vet/bc_entitlements_calculator/vod_calc.html",
      },
    ],
  },
  {
    title: "CAE Wizard",
    id: "cae-wizard",
    description:
      "A custom made step-by-step interactive wizard that helps customers envision the advantages of Context Aware Encoding. By answering the questions in each step, the wizard provides specific results for each use case, as well as specific improvements to bandwidth and storage",
    tags: ["Wizard", "CAE"],
    image: image.poc.caeWizard,
    url: "https://vet.brightcove.com/cae-calc/",
    links: [
      {
        title: "Link to Wizard",
        url: "https://vet.brightcove.com/cae-calc/",
      },
    ],
  },
  {
    title: "Enterprise Calculator",
    id: "enterprise-calc",
    description:
      "A handy tool to help you during the sales process. It features an Enterprise-specific calculation, as well as useful tools such as a storage and bandwidth forecast graph that can be exported for use in documentation or presentations.",
    tags: ["Enterprise", "Calculator"],
    image: image.poc.enterpriseCalc,
    url: "https://calc.brightcove.com/enterprise/",
    links: [
      {
        title: "Link to Calculator",
        url: "https://calc.brightcove.com/enterprise/",
      },
    ],
  },
];
