import React, { Component } from 'react';
import { Route, withRouter } from 'react-router';
import language from '../../../../lang';

class LayoutRoute extends Component {
    state = {
        lang: 'en',
    };

    async componentDidMount() {
        const lang = localStorage.getItem('language') || 'en';
        this.setState({ lang });
    }

    setLanguage = lang => {
        this.setState({ lang }, () => {
            localStorage.setItem('language', lang);
        });
    }

    render() {
        const { component: Component, ...rest } = this.props;
        let { lang } = this.state;

        const renderFunc = (
            <div>
                <Component lang={ lang } langText={ language[lang] } { ...this.props } />
                {/* <Footer lang={ lang } setLanguage={ this.setLanguage } langText={ language[lang]['footer'] } /> */}
            </div>
        );

        // Return React Router's route with render function
        return <Route render={ () => renderFunc } { ...rest } />;
    }
}

export default withRouter(LayoutRoute);
