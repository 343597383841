import React from "react";
import { Router, Switch } from "react-router";
import { createBrowserHistory } from "history";
import { Security, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import LayoutRoute from "./modules/Core/layouts/SiteLayout";

import Home from "./modules/Home/containers/Home";
// import SignIn from './modules/SignIn';

import NotFound from "./modules/Core/components/NotFoundPage";

// okta config import
import oktaConfig from "./config/oktaConfig";

import "./global.scss";

// const browserHistory = createBrowserHistory();

const Routes = () => {
  const oktaAuth = new OktaAuth(oktaConfig);
  const browserHistory = createBrowserHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    browserHistory.replace(
      toRelativeUrl(originalUri || "/", window.location.origin)
    );
  };
  return (
    // <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
    <Router history={browserHistory} basename={"."}>
      <Switch>
        {/* OKTA */}
        {/*<LayoutRoute
          path={`${process.env.PUBLIC_URL}/sign-in/callback`}
          component={LoginCallback}
        />*/}

        
        {/* Sign In Redirect */}
        {/* <LayoutRoute exact path={ `${process.env.PUBLIC_URL}/` } component={ SignIn } /> */}
        {/* HOME */}
        {/* <SecureRoute> */}
        <LayoutRoute
          exact
          path={`${process.env.PUBLIC_URL}/`}
          component={Home}
        />
        {/* </SecureRoute> */}

        {/* NOT FOUND */}
        <LayoutRoute exact path="*" component={NotFound} />
      </Switch>
    </Router>
    // </Security>
  );
};

export default Routes;
