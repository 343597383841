import helper from './helper';
import home from './home';
import logo from './logo';
import poc from './poc';

export default {
    helper,
    home,
    logo,
    poc,
};
